<script>
import LinkForm from "@/views/links/linkForm";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility";

@Component({
  inject: {
    standService: "standService",
    eventService: "eventService",
    fileUploadService: "fileUploadService",
    toastService: "toastService",
  },
})
export default class LinkNew extends mixins(LinkForm) {
  formId = "new-link-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      const requestModel = Utility.clone(this.model);
      requestModel.x = +this.model.x;
      requestModel.y = +this.model.y;
      requestModel.height = +this.model.height;
      requestModel.width = +this.model.width;
      requestModel.localizations = [
        ...this.model.localizations,
        ...this.model.hotspotLocalizations,
      ];

      requestModel.hotspotOptions = this.prepareHotspotOptions();

        await this.linkService.create(requestModel);
      this.$router.push(`/link/${this.$route.params.idParent}/${this.entityType}`);
      this.toastService.success(this.translations.success.link_create);
    } catch (error) {
      this.model.localizations = [];
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations?.pageTitles?.link_new;
  }

  afterCreate() {
    this.model = {
      from: this.$route.params.idParent,
      width: null,
      height: null,
      x: null,
      y: null,
      icon: null,
      type: null,
      to: null,
      externalLink: null,
      subType: null,
      previewVideoImage: null,
      autoOpen: null,
      hotspotEnabled: false,
      hotspotType: null,
      hotspotPosition: null,
      hotspotAnimation: null,
      hotspotOptions: {},
      hotspotFontSize: "M",
      hotspotLocalizations: [],
      localizations: [],
    };
    this.isReady = true;
  }
}
</script>
